import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { FILE_URL, FILE_URL_API } from "../../utils/constants";
import PropTypes from "prop-types";
import Editor from "ckeditor5-custom-build";

var cookie = require("cookie-cutter");

const UPLOAD_ENDPOINT = "Files/uploads";

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        const access_token = cookie.get("access_token");

        loader.file.then((file) => {
          body.append("formFileCollection", file, `ck_img_${Date.now()}.png`);
          //   body.append("folderId", );
          let headers = new Headers();
          headers.append("Authorization", `Bearer ${access_token}`);
          fetch(`${FILE_URL_API}${UPLOAD_ENDPOINT}`, {
            method: "post",
            body: body,
            headers: headers,
            // mode: "no-cors"
          })
            .then((res) => res.json())
            .then((res) => {
              resolve({
                default: `${FILE_URL}/${res.data[0].url}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}

function CKHtmlEditor({ value, onInit, initHeight = null }) {
  return (
    <CKEditor
      config={{
        // plugins: [TableProperties],
        extraPlugins: [uploadPlugin],
        uiColor: "#000000",
        toolbar: {
          items: [
            "heading",
            "|",
            "fontfamily",
            "fontsize",
            "|",
            "alignment",
            "|",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "subscript",
            "superscript",
            "|",
            "link",
            "|",
            "outdent",
            "indent",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "|",
            "code",
            "codeBlock",
            "|",
            "insertTable",
            "|",
            "uploadImage",
            "mediaEmbed",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
          shouldNotGroupWhenFull: true,
        },
        heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
              { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
              { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
      },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties",
          ],
        },
      }}
      editor={Editor}
      data={value}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        // console.log("Editor is ready to use!", editor);
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            initHeight || "500px",
            editor.editing.view.document.getRoot()
          );
        });

        // Insert the toolbar before the editable area.
        editor.ui.view.editable.element.parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.view.editable.element
        );
        onInit(editor);
      }}
      // onChange={(event, editor) => {
      //   const data = editor.getData();
      //   console.log({ event, editor, data });
      //   // onValueChanged(data);
      // }}
      // onBlur={(event, editor) => {
      //   console.log("Blur.", editor);
      // }}
      onFocus={(event, editor) => {
        onInit(editor);
      }}
    />
  );
}

CKHtmlEditor.propTypes = {
  value: PropTypes.string,
  onInit: PropTypes.func,
  initHeight: PropTypes.number,
};

export default CKHtmlEditor;
